/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React from 'react';
import { PlayButtonSlate } from './PlayButtonSlate';
import { LoadingSlate } from './LoadingSlate';
import { ReplayOverlay } from './ReplayOverlay';
import { ExpiredSlate } from './ExpiredSlate';

/**
 * @typedef {{
 *   src: string;
 *   height: number | undefined;
 *   width: number | undefined;
 *   alt: string;
 * }} ImageAttributes
 */

/**
 * Select which slate should be shown given the current state
 * @param {object} props
 * @param {boolean} props.isPaused whether the video is paused
 * @param {boolean} props.isExpired whether the video is expired
 * @param {boolean} props.isHidden whether the video is explicitly hidden
 * @param {boolean} props.hasPlaybackStarted whether the
 * video has started at all for the current video, regardless of whether it's
 * currently paused
 * @param {boolean} props.lastVideoIsEnded whether playlist is
 * complete and doesn't repeat
 * @param {() => void} props.onPlayClick
 * @param {() => void} props.onReplayClick
 * @param {string} props.teaseImageUrl - Image URL
 * @param {string} props.teaseImageAltText - Image alt text
 * @param {string} props.videoHref
 */
export function Slate({
  isPaused,
  isExpired,
  isHidden,
  hasPlaybackStarted,
  lastVideoIsEnded,
  onPlayClick,
  onReplayClick,
  videoHref,
  teaseImageUrl,
  teaseImageAltText,
}) {
  if (isHidden) return null;
  if (isExpired) {
    return <ExpiredSlate />;
  }
  if (!hasPlaybackStarted) {
    return isPaused ? (
      <PlayButtonSlate
        onClick={onPlayClick}
        href={videoHref}
        teaseImageUrl={teaseImageUrl}
        teaseImageAltText={teaseImageAltText}
      />
    ) : (
      <LoadingSlate />
    );
  }
  if (lastVideoIsEnded) {
    return <ReplayOverlay onClick={onReplayClick} />;
  }
  return null;
}
