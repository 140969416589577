import defaultsDeep from 'lodash/defaultsDeep';

const newsCapabilities = {
  flag: {
    sltp: 'on',
    emcr: 'on',
    slcb: 'on',
    nucr: 'on',
    sbid: 'on',
    fbad: 'on',
    aeti: 'on',
    slif: 'on',
    vicb: 'on',
    amcb: 'on',
    dtrd: 'off',
  },
};

const tlmdCapabilities = {
  flag: {
    amcb: 'on',
    dtrd: 'off',
  },
};

/**
 * @typedef {import('@nbcnews/gamma').FreewheelAdConfig} FreewheelAdConfig
 */

/** @type {{
 *   defaults: Record<string, Partial<FreewheelAdConfig>>
 *   mobile: Record<string, Partial<FreewheelAdConfig>>
 *   embedded: Record<string, Partial<FreewheelAdConfig>>
 *   liveVideoDesktop: Record<string, Partial<FreewheelAdConfig>>
 *   liveVideoMobile: Record<string, Partial<FreewheelAdConfig>>
 * }} */
const verticalAdConfigLib = {
  // defaults for all, patched by 'mobile' settings below, if mobile
  defaults: {
    today: {
      freewheel: {
        sectionid: 'today_canonical_video',
        afid: '137705821',
        sfid: '7006049',
        capabilities: newsCapabilities,
      },
    },
    msnbc: {
      freewheel: {
        sectionid: 'msnbc_allos_web_ondemand',
        afid: '137705082',
        sfid: '7006049',
        capabilities: newsCapabilities,
      },
    },
    news: {
      freewheel: {
        sectionid: 'nbcnews_allos_web_ondemand',
        afid: '137704898',
        sfid: '7006049',
        capabilities: newsCapabilities,
      },
    },
    noticias: {
      freewheel: {
        sectionid: 'telemundo_noticias_vod',
        afid: '137391198',
        sfid: '7005949',
        capabilities: tlmdCapabilities,
      },
    },
    deportes: {
      freewheel: {
        sectionid: 'telemundo_deportes_vod',
        afid: '137391198',
        sfid: '7005949',
        capabilities: tlmdCapabilities,
      },
    },
    localstationdeportes: {
      freewheel: {
        sectionid: 'telemundo_desktop_computer_web_ondemand_deportes_local_embed',
        afid: '137391198',
        sfid: '7005949',
        capabilities: tlmdCapabilities,
      },
    },
    entretenimiento: {
      freewheel: {
        sectionid: 'telemundo_entretenimiento_vod',
        afid: '137391198',
        sfid: '7005949',
        capabilities: tlmdCapabilities,
      },
    },
    shows: {
      freewheel: {
        sectionid: 'telemundo_shows_vod',
        afid: '137391198',
        sfid: '7005949',
        capabilities: tlmdCapabilities,
      },
    },
    telemundo: {
      freewheel: {
        sectionid: 'telemundo_vod',
        afid: '137391198',
        sfid: '7005949',
        capabilities: tlmdCapabilities,
      },
    },
  },
  // `mobile` overrides `defaults`
  mobile: {
    today: {
      freewheel: {
        sectionid: 'today_canonical_video_mobile',
        sfid: '7003948',
      },
    },
    msnbc: {
      freewheel: {
        sectionid: 'msnbc_mobile_allos_web_ondemand',
        sfid: '7003948',
      },
    },
    news: {
      freewheel: {
        sectionid: 'nbcnews_mobile_allos_web_ondemand',
        sfid: '7003948',
      },
    },
    noticias: {
      freewheel: {
        sectionid: 'telemundo_noticias_vod_hh',
        sfid: '7003959',
      },
    },
    deportes: {
      freewheel: {
        sectionid: 'telemundo_deportes_vod_hh',
        sfid: '7003959',
      },
    },
    localstationdeportes: {
      freewheel: {
        sectionid: 'telemundo_mobile_allos_web_ondemand_deportes_local_embed',
        sfid: '7003959',
      },
    },
    entretenimiento: {
      freewheel: {
        sectionid: 'telemundo_entretenimiento_vod_hh',
        sfid: '7003959',
      },
    },
    shows: {
      freewheel: {
        sectionid: 'telemundo_shows_vod_hh',
        sfid: '7003959',
      },
    },
    telemundo: {
      freewheel: {
        sectionid: 'telemundo_vod_hh',
        sfid: '7003959',
      },
    },
  },
  embedded: {
    today: {
      freewheel: {
        sectionid: 'today_embed_noads',
      },
    },
    msnbc: {
      freewheel: {
        sectionid: 'msnbc_embed_noads',
      },
    },
    news: {
      freewheel: {
        sectionid: 'nbcnews_embed_noads',
      },
    },
    noticias: {
      freewheel: {
        sectionid: 'telemundo_embed_noads',
      },
    },
    deportes: {
      freewheel: {
        sectionid: 'telemundo_embed_noads',
      },
    },
    localstationdeportes: {
      freewheel: {
        sectionid: 'telemundo_desktop_computer_web_ondemand_deportes_local_embed',
      },
    },
    entretenimiento: {
      freewheel: {
        sectionid: 'telemundo_embed_noads',
      },
    },
    shows: {
      freewheel: {
        sectionid: 'telemundo_embed_noads',
      },
    },
    telemundo: {
      freewheel: {
        sectionid: 'telemundo_embed_noads',
      },
    },
  },
  liveVideoDesktop: {
    noticias: {
      freewheel: {
        sectionid: 'telemundo_noticias_live',
      },
    },
    deportes: {
      freewheel: {
        sectionid: 'telemundo_deportes_live',
      },
    },
    localstationdeportes: {
      freewheel: {
        sectionid: 'telemundo_desktop_computer_web_ondemand_deportes_local_embed',
      },
    },
    entretenimiento: {
      freewheel: {
        sectionid: 'telemundo_entretenimiento_live',
      },
    },
    shows: {
      freewheel: {
        sectionid: 'telemundo_shows_live',
      },
    },
    telemundo: {
      freewheel: {
        sectionid: 'telemundo_live',
      },
    },
  },
  liveVideoMobile: {
    noticias: {
      freewheel: {
        sectionid: 'telemundo_noticias_live_hh',
      },
    },
    deportes: {
      freewheel: {
        sectionid: 'telemundo_deportes_live_hh',
      },
    },
    localstationdeportes: {
      freewheel: {
        sectionid: 'telemundo_mobile_allos_web_ondemand_deportes_local_embed',
      },
    },
    entretenimiento: {
      freewheel: {
        sectionid: 'telemundo_entretenimiento_live_hh',
      },
    },
    shows: {
      freewheel: {
        sectionid: 'telemundo_shows_live_hh',
      },
    },
    telemundo: {
      freewheel: {
        sectionid: 'telemundo_live_hh',
      },
    },
  },
};

/**
 * @param {object} BrandAdConfigArgs
 * @param {string} BrandAdConfigArgs.vertical
 * @param {boolean | null} BrandAdConfigArgs.isLiveVideo
 * @param {boolean | null} BrandAdConfigArgs.isMobile
 * @param {boolean | null} BrandAdConfigArgs.isEmbedded
 * @returns {Partial<FreewheelAdConfig>}
 */
export function getBrandAdConfig({
  vertical, isMobile = false, isEmbedded = false, isLiveVideo = false,
}) {
  // If isMobile is true, then the mobile config will patch
  // If isEmbedded is true it will overwrite the sectionId to ensure no ads csid is present to prevent ads
  // the `defaults` config.

  const effectiveVertical = (vertical === 'better' || vertical === 'think') ? 'news' : vertical;

  const verticalAdConfigDefaults = verticalAdConfigLib?.defaults?.[effectiveVertical];
  const verticalAdConfigMobile = verticalAdConfigLib?.mobile?.[effectiveVertical];
  const verticalAdConfigEmbedded = verticalAdConfigLib?.embedded?.[effectiveVertical];
  const verticalAdConfigLiveVideoDesk = verticalAdConfigLib?.liveVideoDesktop?.[effectiveVertical];
  const verticalAdConfigLiveVideoMobile = verticalAdConfigLib?.liveVideoMobile?.[effectiveVertical];

  const brandAdConfig = defaultsDeep(
    {},
    isLiveVideo && !isMobile ? verticalAdConfigLiveVideoDesk : {},
    isLiveVideo && isMobile ? verticalAdConfigLiveVideoMobile : {},
    isEmbedded ? verticalAdConfigEmbedded : {},
    isMobile ? verticalAdConfigMobile : {},
    verticalAdConfigDefaults,
  );

  return brandAdConfig;
}
